/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import 'animate.css';
body[data-theme="dark"] {
    
    ion-content,
    ion-toolbar ,ion-list {
        --background: #313131 !important;
        --ion-item-background:#313131 !important;
        --color:white;
    }
    ion-item{
        --background: #9f9c9c1c !important;
    }
    ion-toggle{
        --color:white;
        --ion-color-primary : white;
    }

}

.custom_modal{
    position: fixed;
    top:56px;
    left:0;
    right:0;
    bottom:0;
    background-color:#fff;
   padding: 16px;
    z-index: 99;
    .custom_modal_inner{
  
      overflow: auto;
      box-shadow: 0 0 23px rgba(0, 0, 0, 0.5);
      border-radius: 10px;
    }
  
    &.withOverlay{
      background-color:rgba(0,0,0,0.5); 
      .custom_modal_inner{
        background-color:#fff; 
        padding: 10px;
      }
    }
    .succesMsg{
      color: #4CAF50;
      font-weight: 300;
    }
  }